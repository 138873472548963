<template>
	<div :style="Bg">
		<Header :title="$t('google.backupTitle')"></Header>
		
		<div class="column-center-content main-content" style="margin-top: 100px;">
			<img style="width: 100px;" src="../../../assets/backup.png"/>
			<label style="padding: 30px 20px 20px 20px; color: #999999; font-size: 14px;">{{$t('google.backupTips')}}</label>
		
			<div style="padding: 10px 20px; width:100%;">
				<div @click="doCopy" style="background-color: #F2F2F2; height: 40px; width: 100%; padding: 0px 10px; display: flex; justify-content: space-between; border-radius: 5px;">
					<label style="line-height: 40px;" v-text="code"></label>
					<label style="line-height: 40px; color: #009245; font-weight: bold;">{{$t('google.copy')}}</label>
				</div>
			</div>
			
			<div class="btn-content" style="margin-bottom: 0px; margin-top: 60px;">
				<v-btn rounded block @click="goNext" style="background-color: #009245; color: white; height: 40px;">
					下一步
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../components/NavHeader.vue'
	import { Toast, Indicator } from 'mint-ui';
	
	export default{
		name: "BackUp",
		components: {
			Header
		},
		mounted() {
			Indicator.open({
				text: '',
				spinnerType: 'fading-circle'
			});
			this.$request.authCode({}).then((data)=>{
				Indicator.close();
				this.code = data.data;
			});
		},
		data:() => ({
			Bg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			code: ''
		}),
		methods: {
			doCopy() {
				var domUrl = document.createElement("input");
				domUrl.value = this.$data.code;
				domUrl.id = "creatDom";
				document.body.appendChild(domUrl);
				// 选择对象
				domUrl.select(); 
				// 执行浏览器复制命令
				document.execCommand("Copy"); 
				let creatDom = document.getElementById("creatDom");
				creatDom.parentNode.removeChild(creatDom);
				Toast({
					message: 'Success',
					position: 'bottom'
				});
			},
			
			goNext() {
				this.$router.push({
					"name": "CodeVerification"
				})
			}
		}
	}
</script>

<style>
</style>
